<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Click the buttons below to construct the proper
        <span class="text-bold">full</span> electron configuration for a neutral
        <chemical-latex :content="atomName" />
        atom.
      </p>
      <electron-configuration-input
        v-model="inputs.input1"
        :max-n="4"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ElectronConfigurationInput from '../inputs/ElectronConfigurationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question25',
  components: {
    ElectronConfigurationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {input1: []},
    };
  },
  computed: {
    chemicalElement() {
      return this.taskState.getValueBySymbol('element').content;
    },
    atomName() {
      return this.chemicalElement.name.toLowerCase();
    },
  },
};
</script>
